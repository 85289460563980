import { del, get, patch, put, post} from "./api";

export const getDataForCleanup = async (id) => {
  const url = `/projects/${id}/data`;
  const data = await get(url);
  console.log("Returned data", data);
  return data;
};

const checkType = (value, type) => {
    if (Array.isArray(value)) {
        return value.includes(type);
    } else {
        return value == type;
    }
}

export const getNamedEntities = async (id: string, type = "org") => {
    let url = `/projects/${id}/names`;
  if (!checkType(type, "org")) {
    url = `${url}?type=${type}`;
    }
    console.log(url);
    const data = await get(url);
    console.log('getNamedEntities', data);
  // console.log("Returned data", data);
  return data;
};

export const updateName = async (
  projectId: string,
  nameId: string,
  value: string
) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const result = await put(url, { name: value });

  return result;
};

export const updateAnswerForUser = async (
  projectId: string,
  user: string,
  answers: string[],
  identifier: string
) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = { identifier, answers };
  const result = await patch(url, data);
  return result;
};

export const deleteUserData = async (projectId: string, user: string) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = await del(url);
  return data;
};

export const deleteName = async (projectId: string, nameId: string) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const data = await del(url);
  return data;
};

export const deleteAnswer = async (user: string, projectId: string, identifier: string, item: string) => {
    let url = `/projects/${projectId}/data/delete-answer`;
    const data = { user, projectId, identifier, item};
    console.log(data);
    const result = await post(url, data);
    return result;
}

//rough will change when i know what the structure is
export const insertAnswer = async (user: string, projectId: string, identifier: string, answer: string) => {
    let url = `/projects/${projectId}/data/insert-answer`;
    const data = { user, projectId, identifier, answer};
    console.log(data);
    const result = await post(url, data);
    return result;
}

// export const onlyEditable = async (data, questionIdentifiers) => {
//   // this ugly looking beast is mangling the cleanup data into an easy format to work with
//   // the result will be an array of users, with each user containing an array of
//   // editable answers
//   return data.map((userData) => {
//     return {
//       ...userData, // include all other props
//       data: userData.data
//         .filter((answers) => {
//           return questionIdentifiers.indexOf(answers.identifier) !== -1; // omit any answers to questions that we can't edit
//         })
//         .reduce((acc, curr) => {
//           return [
//             ...acc,
//             ...curr.answers
//               .filter((a) => a != "")
//               .map((a, index) => {
//                 return {
//                   identifier: curr.identifier,
//                   answer: a,
//                   allAnswers: curr.answers,
//                   indexForQuestion: index,
//                 };
//               }),
//           ];
//         }, []),
//     };
//   });
//   return data;
// };

export const onlyEditable = async (data, questionIdentifiers) => {
  try {
    return data.map((userData) => {
      if (!userData.data) {
        return { ...userData, data: [] };
      }

      // Flatten the nested set data structure
      const flattenedData = userData.data.flatMap(set => {
        return Object.keys(set).flatMap(setKey => {
        const questions = set[setKey];
        return Object.values(questions).flatMap(questionData => {
          console.log(questionData);
          // Ensure questionData is an object and has the necessary fields
          if (!questionData || !questionData.identifier || !Array.isArray(questionData.answers)) {
            return [];
          }

          // Extract identifier and answers
          const { identifier, answers } = questionData;

          // Check if the identifier is in the list of questionIdentifiers
          if (!questionIdentifiers.includes(identifier)) {
            return [];
          }

          // Process the answers and include their index for the question
          return answers.map((answer, index) => ({
            identifier,
            answer,
            allAnswers: answers,
            indexForQuestion: index,
          }));
        });
      })
    });

      return {
        ...userData,
        data: flattenedData.filter(item => item.answer !== "")
      };
    });
  } catch (error) {
    console.error('Error in onlyEditable:', error);
    return [];
  }
};


//

/*export const allEditable = async (data) => {
    console.log('indata', data);
    try {
        return data.map((userData) => {
            if (!userData.data) {
                return { ...userData, data: [] };
            }

            const UsersData = userData.data.flatMap(set => {
              return Object.keys(set).flatMap(setKey => {
                const questions = set[setKey];
                return Object.entries(questions).map(([index, questionData]) => {
                  console.log(index, questionData);
                  // Handle null or empty question data
                  if (!questionData) {
                    return { identifier: 'question--' + index, answers: ['placeholder'] };
                  }
      
                  const { identifier, answers } = questionData;
      
                  //think this needs work 
                  // For index 0, replace non-object answers with placeholder
                  if (index == 0 && answers && typeof answers[0] !== 'object') {
                    return { identifier: 'question--' + index, answers: ['placeholder'] };
                  }
      
                  return { identifier, answers };
                });
              });
            });
        

            return {
                ...userData,
                data: UsersData
                    .filter((answers) => {
                        return answers && answers.identifier ;
                    })
                    .reduce((acc, curr) => {
                        if (!curr || !curr.answers) {
                            return acc;
                        }                       
                        return [
                            ...acc,
                            ...curr.answers
                                .filter((a) => a != "")
                                .map((a, index) => {
                                    return {
                                        identifier: curr.identifier,
                                        answer: a,
                                        allAnswers: curr.answers,
                                        indexForQuestion: index,
                                    };
                                }),
                        ];
                    }, []),
            };
        });
    } catch (error) {
        console.error('Error in onlyEditable:', error);
        return [];
    }
}*/

export const allEditable = async (data) => {
  console.log('indata', data);
  try {
      return data.map((userData) => {
          if (!userData.data) {
              return { ...userData, data: [] };
          }

          let UsersData = [...userData.data];
          console.log(UsersData);
          //create a value at the null values
          UsersData.forEach((q, index) => {
              if (q == null) {
                  UsersData[index] = { identifier: 'question--' + index, answers: ['placeholder'] }
              } else {
                  //this will only work for quesiton 0 
                  if (index == 0) {
                      if (typeof (q.answers[0]) != 'object'){
                          UsersData[index] = { identifier: 'question--' + index, answers: ['placeholder'] }
                      }
                  }
                 
              }
          });
      

          return {
              ...userData,
              data: UsersData
                  .filter((answers) => {
                      return answers && answers.identifier ;
                  })
                  .reduce((acc, curr) => {
                      if (!curr || !curr.answers) {
                          return acc;
                      }                       
                      return [
                          ...acc,
                          ...curr.answers
                              .filter((a) => a != "")
                              .map((a, index) => {
                                  return {
                                      identifier: curr.identifier,
                                      answer: a,
                                      allAnswers: curr.answers,
                                      indexForQuestion: index,
                                  };
                              }),
                      ];
                  }, []),
          };
      });
  } catch (error) {
      console.error('Error in onlyEditable:', error);
      return [];
  }
}